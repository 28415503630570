<template>
<div class="scroll-y-con" v-if="data">
  <div class="top-title">
    <div>{{`订单ID: ${order_no} (${formatDetailOrderStatus(data.order_status)})`}}
      <template v-if="data.order_status == 1 && data.order_source == 10">
        <a-button type="link" @click="showPay=true">选择支付方式</a-button>
        <pay-tip />
      </template>
      <template v-if="isOtherPlatOrder">
        <a-button type="link" class="ml-4 p-0" 
          @click="openOtherPlatOrder">查看第三方订单</a-button>
      </template>
    </div>
    <div>
      <span class="text-sm">
        <span style="font-weight:400;">订单来源：</span>
        <template>{{formatOrderSource(data.order_source)}}</template>
        <!-- <template v-if="data.order_source==2"> - {{data.source_outlets_name}}</template> -->
        <template v-if="data.order_source==15"> - {{data.add_outlets_name}}</template>
        <!-- <template v-if="data.is_cash_delivery_order==2">（货到付款）</template> -->
        <!-- <span v-if="data.place_order_enterprise_name" class="ml-1" style="font-weight:400;">（大客户-{{data.place_order_enterprise_name}}）</span> -->
      </span>
      <a-button
        v-if="[1,2,3].includes(data.order_status)"
        type="link" class="ml-4 p-0"
        @click="handlerCancel"
      >取消订单</a-button>
      <a-tag v-if="data.status == -1" class="ml-4 mr-0" color="red">订单已删除</a-tag>
    </div>
  </div>

  <div class="page-content">
    <div v-if="isInBlackList" class="mb-2" style="background:#eee; padding:10px 20px;">
      <div>
        <span style="color:#f00;">此用户是黑名单用户</span>
      </div>
      <div class="flex align-center mt-2">
        <span>备注说明: </span>
        <span class="ml-2">{{ blackListRemarks }}</span>
      </div>
    </div>
    
    <div>
      <a-row>
        <a-col :span="8">
          <span>{{ data.order_source === 10 || data.order_source === 15 ? '下单人' : '昵称' }}：</span>
          <span>{{ data.place_order_name }}</span>
          <a-button v-if="data.member_id" class="ml-2" style="padding:0;"
              type="link" @click="openUserDetail">查看用户</a-button>
        </a-col>
        <a-col :span="8">
          <span>{{ data.order_source === 10 || data.order_source === 15 ? '下单手机号' : '绑定手机号' }}：</span>
          <span>{{ data.place_order_phone }}</span>
        </a-col>
        <a-col :span="8">
          <span>下单时间：</span>
          <span>{{ data.order_add_time }}</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col class="flex items-start" :span="24">
          <span style="width:80px; margin-top: 5px;">下单备注:</span>
          <a-textarea style="flex:1;"
            :class="{ 'has-update': hasDataUpdate('order_remarks', data.order_remarks) }"
            v-model="formState.order_remarks"
            placeholder="请输入备注信息（顾客可见）"
            :auto-size="{ minRows: 1, maxRows: 6 }"
          />
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col class="flex items-start" :span="24">
          <span style="width:80px; margin-top: 5px;">客服备注:</span>
          <div style="flex:1;">
            <a-textarea style="flex:1;"
              v-model="formState.customer_service_remarks"
              placeholder="顾客不可见"
              :auto-size="{ minRows: 1, maxRows: 6 }"
            />
            <div>
              <a-button class="mt-2 mr-2" type="primary" size="small" 
                  @click="saveCustomerRemarks">保存备注</a-button>
              <span style="color:#aaa;font-size:12px;">*会触发门店自动打印（订单完成后除外）</span>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="mt-3">
        <a-col class="flex items-start" :span="24">
          <span style="width:80px; margin-top: 5px;">内部备注:</span>
          <div style="flex:1;">
            <a-textarea
              v-model="formState.private_remarks"
              placeholder="顾客不可见"
              :auto-size="{ minRows: 1, maxRows: 6 }"
            />
            <div>
              <a-button class="mt-2 mr-2" type="primary" size="small" 
                  @click="savePrivateRemarks">保存备注</a-button>
              <span style="color:#aaa;font-size:12px;">*不会触发门店自动打印</span>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单金额：</span>
          <span>{{ data.order_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span>运费金额：</span>
          <span>{{ data.freight_amount }}元</span>
        </a-col>
        <!-- <a-col :span="6">
          <span style="width:110px">全场活动优惠：</span>
          <span>{{ data.all_event_offers_amount }}元</span>
        </a-col> -->
        <a-col :span="8">
          <span>支付状态：</span>
          <span v-if="data.pay_status == 2">
            已支付
            <span style="font-size:12px;color:#999;"
              >（{{ data.order_financial_account_name }}）</span>
          </span>
          <a-tag v-if="data.pay_status == 1" class="mr-0" color="red">未支付</a-tag>
          <!-- // test 支付方式没加呢 -->
          <span v-if="data.is_cash_delivery == 2" class="btn-link ant-btn-link" @click="showPaySub">
            <a-select
              ref="payTypeSelect"
              class="ml-2"
              default-value="formState.pay_subtype"
              style="width:140px; opacity:0; visibility:hidden; position:absolute; margin-top:-10px;"
              v-model="formState.pay_subtype">
                <a-select-option v-for="item in subCODPayTypeList"
                  :key="item.id"
                  :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
            <template>
              （货到付款-{{getCODPayType(formState.pay_subtype)}} <svg viewBox="64 64 896 896" data-icon="down" width="0.8em" height="0.75em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>）
            </template>
          </span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单价格：</span>
          <span>{{ data.goods_amount }}元</span>
        </a-col>
        <!-- <a-col :span="5">
          <span>会员折扣：</span>
          <span>{{ data.vip_discount_amount }}元</span>
        </a-col> -->
        <!-- <a-col :span="6">
          <span style="width:110px" class="inline-block  text-right">优惠券优惠：</span>
          <span>{{ data.coupon_offers_amount }}元</span>
        </a-col> -->
        <a-col :span="5">
        </a-col>
        <a-col :span="8">
          <span>支付时间：</span>
          <span>{{ data.pay_time }}</span>
        </a-col>
      </a-row>

      <div class="mt-3">
        <base-table :columnsData="pay_column" :customHeight="100" :tableData="payData" rowKey="key">
          <!-- <template #cash_delivery_amount="{record}">
            <span v-if="data.pay_status == 2 && data.pay_type == 10"
              >{{getCODPayType(data.pay_subtype)}} {{record.cash_delivery_amount}}</span>
            <span v-else>{{record.cash_delivery_amount}}</span>
          </template> -->
        </base-table>
      </div>

      <div class="mt-3">
        <a-collapse :default-active-key="['1', '2']">
<!-- 网点子订单 -->
          <a-collapse-panel
            :show-arrow="false"
            key="1"
            :header="`【${outletOrder.suborder_type === 1 ? '自提' : '配送'}】子订单：${outletOrder.suborder_no}(${outletOrder.suborder_statusname})`"
          >
            <template slot="extra">
              <a v-if="data.order_status !=-1 && outletOrder.suborder_type == 2 && outletOrder.suborder_status >= 5"
                class="pt-4 pb-4 ml-2" type="link"
                @click.stop="handlerQuery(outletOrder.suborder_type)"
              >配送进度</a>
            </template>

            <!--如果是自提订单 显示这些-->
            <template v-if="outletOrder.suborder_type === 1">
              <a-row class="flex">
                <div class="col-item">
                  <span class="text-right" style="width:70px;">取货人：</span>
                  <span>
                    <a-input
                      :class="{ 'has-update': hasDataUpdate('pick_up_info.recipient_name', outletOrder.pick_up_info.recipient_name) }"
                      v-model="outletOrder.pick_up_info.recipient_name"
                    />
                  </span>
                </div>
                <div class="col-item">
                  <span class="text-right" style="width:70px;">手机号：</span>
                  <span>
                    <a-input
                      :class="{ 'has-update': hasDataUpdate('pick_up_info.recipient_phone', outletOrder.pick_up_info.recipient_phone) }"
                      v-model="outletOrder.pick_up_info.recipient_phone"
                    />
                  </span>
                </div>
                <div class="col-item" style="min-width:450px;">
                  <div class="flex items-center flex-1">
                    <span>取货时间：</span>
                    <time-picker2 style="flex:1;" :class="{ 'has-update': isTimeUpdate }"
                      :value.sync="goods_time" />
                  </div>
                </div>
              </a-row>
              <a-row class="mt-3 flex">
                <div class="col-item">
                  <span class="text-right" style="width:70px;">自提门店：</span>
                  <span>{{ outletOrder.outlets_name }}</span>
                  <a-button class="ml-2" type="primary" size="small"
                      v-if="isAllEdit"
                      @click="handlerSelectOutlets">修改</a-button>
                </div>
              </a-row>
            </template>

            <!--如果是配送订单 显示这些-->
            <template v-if="outletOrder.suborder_type === 2">
              <!-- 配送 联系人 -->
              <a-row class="flex" v-if="outletOrder.delivery_info.contacts_phone">
                <div class="col-item">
                  <span class="text-right" style="width:70px;">联系人：</span>
                  <span>
                    <a-input
                      style="width:120px;"
                      disabled
                      :class="{ 'has-update': hasDataUpdate('delivery_info.contacts_name', outletOrder.delivery_info.contacts_name) }"
                      v-model="outletOrder.delivery_info.contacts_name"
                    />
                  </span>
                </div>
                <div class="col-item">
                  <span class="text-right" style="width:70px;">联系电话：</span>
                  <span>
                    <a-input
                      style="width:140px;"
                      disabled
                      :class="{ 'has-update': hasDataUpdate('delivery_info.contacts_phone', outletOrder.delivery_info.contacts_phone) }"
                      v-model="outletOrder.delivery_info.contacts_phone"
                    />
                  </span>
                </div>
              </a-row>
              <!-- 配送 收货人+时间 -->
              <a-row class="mt-3 flex" align="middle">
                <div class="col-item">
                  <span class="text-right" style="width:70px;">收货人：</span>
                  <span>
                    <a-input style="width:120px;" v-model="outletOrder.delivery_info.consignee_name" />
                  </span>
                </div>
                <div class="col-item">
                  <span class="text-right" style="width:70px;">手机号：</span>
                  <span>
                    <a-input style="width:140px;" v-model="outletOrder.delivery_info.consignee_phone" />
                  </span>
                </div>
                <div class="col-item" style="min-width:450px;">
                  <div class="flex items-center flex-1">
                    <span>收货时间：</span>
                    <time-picker2 style="flex:1;" :class="{ 'has-update': isTimeUpdate }"
                      :value.sync="goods_time" />
                  </div>
                </div>
              </a-row>
              <!-- 配送 配送站+地址 -->
              <a-row class="mt-3 flex">
                <div class="col-item">
                  <span class="text-right" style="width:70px;">配送网点：</span>
                  <span>{{ outletOrder.outlets_name }}</span>
                  <a-button class="ml-2" type="primary" size="small"
                      v-if="isAllEdit"
                      @click="handlerSelectOutlets">修改</a-button>
                </div>
                <div class="col-item">
                  <template v-if="isCustomDlvr">
                    <span class="text-right" style="width:90px;">自定义运费：</span>
                    <a-input-number style="width:110px;" placeholder="运费"
                      :min="0" :step="1" 
                      v-model="newFreight" @change="onCustomFreightChange" /> 
                    <span class="ml-1">元</span>
                  </template>
                </div>
                <div class="col-item flex-1">
                  <span class="text-right" style="width:70px;">收件地址：</span>
                  <template v-if="isAllEdit">
                    <a-select
                      show-search
                      v-model="outletOrder.delivery_info.consignee_address"
                      placeholder="请输入所在地址"
                      style="flex:1;width:0;"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleSearch"
                      @change="handleChange"
                    >
                      <a-select-option v-for="d in addressList" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                    </a-select>
                    <a-input style="width:150px" v-model="outletOrder.delivery_info.consignee_address_detail" allowClear placeholder="请输入门牌号"></a-input>
                  </template>
                  <template v-else>
                    <span>{{outletOrder.delivery_info.consignee_address}}</span>
                  </template>
                </div>
              </a-row>
            </template>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">购买商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              class="mt-2"
              :customHeight="300"
              :columnsData="detail_columns"
              rowKey="sku_id"
              :tableData="leftOutletGoodsList"
            ></base-table>

            <!--蛋糕配件-->
            <div class="mt-3" v-if="cakeList && cakeList.length > 0">
              <div class="text-sm font-bold text-black">蛋糕配件</div>
              <div class v-for="(item,i) in cakeList" :key="i">
                <div class="mt-2 flex items-center">
                  <div>
                    <span class="font-bold">{{ item.goods_name }}</span>
                    <!-- <span class="ml-2" style="color:#aaa;">{{getSkuInfo(item.order_goods_id)['flavor_name']}} / {{getSkuInfo(item.order_goods_id)['specification_name']}}</span> -->
                    <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(item.order_goods_id)['specification_name']}}</span>
                  </div>
                  <div class="ml-6">
                    <span class="text-right" style="width:70px;">蜡烛:</span>
                    <!-- <a-cascader
                      class="ml-2"
                      :class="{ 'has-update': hasCakeUpdate('birthday_candle', item.birthday_candle.join(':'), i) }"
                      :options="candleOptions"
                      v-model="item.birthday_candle"
                    />-->
                    <birthday-candle :class="{ 'has-update': hasCakeUpdate('birthday_candle', item.birthday_candle, i) }" :value.sync="item.birthday_candle"></birthday-candle>
                  </div>
                  <div class="ml-2">
                    <span>餐具:</span>
                    <a-select
                      class="ml-2"
                      :class="{ 'has-update': hasCakeUpdate('diners_number', item.diners_number, i) }"
                      default-value="1"
                      style="width:100px"
                      v-model="item.diners_number"
                    >
                      <a-select-option
                        v-for="(val, index) in 41"
                        :value="index" :key="index"
                      >{{ index }}</a-select-option>
                    </a-select>
                  </div>
                  <div class="ml-2">
                    <span>一次性餐具:</span>
                    <a-select
                      class="ml-2"
                      default-value="0"
                      style="width:100px"
                      v-model="item.plastic_tableware_number"
                    >
                      <a-select-option
                        v-for="(val, index) in 41"
                        :value="index" :key="index"
                      >{{ index }}</a-select-option>
                    </a-select>
                  </div>
                  <div class="ml-2">
                    <span class="text-right" style="width:70px;">生日牌:</span>
                    <a-input
                      class="ml-2"
                      :class="{ 'has-update': hasCakeUpdate('birthday_card', item.birthday_card, i) }"
                      style="width:150px;"
                      v-model="item.birthday_card"
                    />
                  </div>
                  <div class="ml-2 flex items-center" style="flex:1;">
                    <span class="text-right" style="width:70px;min-width:70px;">贺卡:</span>
                    <!-- <a-input
                      class="ml-2"
                      :class="{ 'has-update': hasCakeUpdate('birthday_greeting_card', item.birthday_greeting_card, i) }"
                      style="width:70%;"
                      v-model="item.birthday_greeting_card"
                    /> -->
                    <a-textarea
                      class="ml-2"
                      :class="{ 'has-update': hasCakeUpdate('birthday_greeting_card', item.birthday_greeting_card, i) }"
                      v-model="item.birthday_greeting_card"
                      :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
                  </div>
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>

        <!-- 底部操作项 -->
        <div class="flex justify-between">
          <div>
            <a-button class="bottom-btn" type="link" @click="isShowFlow = true">查看流水</a-button>
            <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">查看操作记录</a-button>
            <template>
              <a-button v-if="invoiceStatus==1" class="bottom-btn" type="link" 
                  @click="isShowEditInvoice = true">发票未开</a-button>
              <a-button v-if="invoiceStatus==2" class="bottom-btn" type="link" 
                  @click="isShowDetailInvoice = true">发票已开</a-button>
              <span v-if="invoiceStatus==-1" class="bottom-btn">未申请发票</span>
            </template>
            <template v-if="!isOtherPlatOrder && data.order_status == 4">
              <template v-if="!data.refund_status">
                <template v-if="!data.refund_switch">
                  <span>售后开关:未设置</span>
                  <a-button class="bottom-btn" type="link" @click="switchRefund(true)">(点击开启)</a-button>
                </template>
                <template v-if="data.refund_switch==1">
                  <span>售后开关:已打开</span>
                  <a-button class="bottom-btn" type="link" @click="switchRefund(false)">(点击关闭)</a-button>
                </template>
                <template v-if="data.refund_switch==2">
                  <span>售后开关:已关闭</span>
                  <a-button class="bottom-btn" type="link" @click="switchRefund(true)">(点击开启)</a-button>
                </template>
              </template>
              <!-- 售后退款 -->
              <template>
                <a-button v-if="!data.refund_status" 
                  class="bottom-btn" type="link" @click="isShowRefundApply = true">申请退款</a-button>
                <a-button v-if="data.refund_status == 40" 
                  class="bottom-btn" type="link" @click="isShowRefundAudit = true">审核退款</a-button>
                <a-button v-if="data.refund_status > 40" 
                  class="bottom-btn" type="link" @click="isShowRefundDetail = true">退款详情</a-button>
              </template>
            </template>
          </div>
          <div>
            <template>
              <a-button class="bottom-btn r" type="link" @click="isShowSmsLog = true">短信历史</a-button>
              <a-button class="bottom-btn r" type="link" @click="showSendSms">发短信</a-button>
            </template>
          </div>
        </div>
      </div>

      <!-- 修改/审核：订单完成前 -->
      <template v-if="[2,3].includes(data.order_status)">
        <div class="bottom-space"></div>
        <div class="operate-fixed-footer flex justify-center items-center mt-3">
          <a-button v-if="data.audit_status==1" class="mr-7" @click="handlerSave(1)">仅保存</a-button>
          <template>
            <a-button type="primary" @click="handlerSave(2)">保存并完成审核</a-button>
            <span class="ml-2" style="color:#aaa;font-size:12px;">*会触发门店自动打印</span>
          </template>
        </div>
      </template>
      <!-- 修改：订单完成后 -->
      <template v-if="[4].includes(data.order_status)">
        <div class="bottom-space"></div>
        <div class="operate-fixed-footer flex justify-center mt-3">
          <a-button type="primary" @click="handlerSave(1)">保存修改</a-button>
        </div>
      </template>
        
    </div>

    <!-- 更换门店/配送站 -->
    <select-outlets-modal
      v-if="isSelectOutlets"
      @ok="handlerSureOutlets"
      @getSplitGoods="handlerOutletNoGoodsSure"
      :show.sync="isSelectOutlets"
      :orderId="order_no"
      :type="outletOrder.suborder_type"
      :outletOrder="outletOrder"
    />
  </div>


  <!-- 售后 -->
  <RefundApply v-if="isShowRefundApply" :show.sync="isShowRefundApply" :data="refundApplyForm" />
  <RefundAudit v-if="isShowRefundAudit" :show.sync="isShowRefundAudit" 
      :id="data.refund_work_sheet_id" :orderNo="order_no" />
  <RefundDetail v-if="isShowRefundDetail" :show.sync="isShowRefundDetail" 
      :id="data.refund_work_sheet_id" :orderNo="order_no" />

  <!-- 取消弹窗（包含其他支付） -->
  <cancel-modal v-if="isShowCancel"
       :show.sync="isShowCancel"
       :payInfo="data"
       @ok="handlerCancelSure" />

  <!-- 支付弹窗 -->
  <pay-modal v-if="showPay" :show.sync="showPay"
       :orderId="order_no"
       :orderType="outletOrder.suborder_type"
       :totalAmount="Number(data.order_amount)"
       @paystatus="getPayStatus"></pay-modal>
  <!-- 支付弹窗 -->
  <repay-modal v-if="showRePay" :show.sync="showRePay"
       :orderId="order_no"
       :orderType="outletOrder.suborder_type"
       :totalAmount="Number(data.order_amount)"
       :newTotalAmount="newAmount"
       @ok="onRePayOk"></repay-modal>

  <!-- 物流 -->
  <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
      :requestID="order_no" 
      :hasDelivery="query_delivery" 
      :hasExpress="query_express" />
  <!-- 弹窗：流水、操作记录 -->
  <flow-modal
    v-if="isShowFlow"
    :show.sync="isShowFlow"
    :orderId="order_no" />
  <opt-modal
    v-if="isShowOptLog"
    :show.sync="isShowOptLog"
    :orderId="order_no" />
  <!-- 弹窗：发票 -->
  <order-invoice-detail v-if="isShowDetailInvoice" :show.sync="isShowDetailInvoice" :requestID="order_no"/>
  <order-invoice-edit v-if="isShowEditInvoice" :show.sync="isShowEditInvoice" :requestID="order_no"/>

  <!-- 弹窗：短信 -->
  <smslog-modal
    v-if="isShowSmsLog"
    :show.sync="isShowSmsLog"
    :orderId="order_no" />
  <sendsms-modal
    v-if="isShowSendSms"
    :show.sync="isShowSendSms"
    :orderId="order_no"
    :phoneList="smsPhoneList" />

</div>
</template>

<script>
import {
  getCakeOrderDetail,
  changeAuditCakeOrder,
  cancelCakeOrder,
  getOrderInvoice,
  updateCustomerRemarks,
  updatePrivateRemarks,
  updateRefundSwitch
} from "@/api/customer"
import { getUserBlackInfo } from "@/api/vip"
import { 
  subCODPayTypeList,
  formatOrderSource 
} from '@/utils/type'
import { searchShopDeliveryArea } from "@/utils/delivery.js"
// import { checkAdcodes } from "@/utils/address.js"
import pageData from "./columns"
import { 
  formatDetailOrderStatus, 
  formatPdSubOrderStatus,
} from "@/utils/type"
import SelectOutletsModal from "./components/select-outlets-modal.vue"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import SmslogModal from "@/components/order/smslog-modal.vue"
import SendsmsModal from "@/components/order/sendsms-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import OrderInvoiceEdit from '../order/components/order-invoice-edit.vue'
import OrderInvoiceDetail from '../order/components/order-invoice-detail.vue'
import TimePicker2 from "@/components/order/time-picker2.vue"
import { getMapPointList, getAdcode } from "@/utils/tMap"
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import CancelModal from '@/components/order/cancel-modal.vue'
import PayTip from "@/components/order/pay-tip.vue"
import PayModal from '../order/components/pay-modal.vue'
import RepayModal from './components/repay-modal.vue'

import RefundApply from "../refund/components/refund-apply"
import RefundAudit from "../refund/components/refund-audit"
import RefundDetail from "../refund/components/refund-detail"

export default {
  components: {
    SelectOutletsModal,
    QueryWindow,
    FlowModal,
    OptModal,
    OrderInvoiceDetail,
    OrderInvoiceEdit,
    TimePicker2, 
    BirthdayCandle,
    CancelModal,
    PayTip,
    PayModal,
    RepayModal,
    SmslogModal,
    SendsmsModal,
    RefundApply,
    RefundAudit,
    RefundDetail,
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      subCODPayTypeList,
      formatOrderSource,
      ...pageData,
      isSelectOutlets: false,
      isUpdateUserInfo: false,
      isShowSplitOrder: false,
      // 发票
      invoiceStatus: 0,
      isShowDetailInvoice: false,
      isShowEditInvoice: false,
      // 售后
      isShowRefundApply: false,
      isShowRefundAudit: false,
      isShowRefundDetail: false,
      refundApplyForm: {},

      isShowFlow: false,
      isShowOptLog: false,
      isShowQuery: false,
      query_delivery: false,
      query_express: false,

      order_no: "",
      payData: [],
      allOutletGoodsList: [],  // 门店子订单的商品
      leftOutletGoodsList: [], // 门店子订单的商品（过滤了拆单商品后）
      giftList: [],
      conponList: [],
      addressList: [],
      addressList2: [],
      addressList3: [],

      data: null,
      outletOrder: {},
      expressOrder: null,
      cakeList: [],
      userInfo: {},

      // 存放有修改的数据，进行数据对比并且增加红框样式
      updateUserInfo: {
        pick_up_delivery_order: {}
      }, 
      
      goods_time: [],
      isTimePoint: false,
      // 提交审核的表单
      formState: {
        order_no: "",
        pay_subtype: 0,

        pick_up_outlets_id: "",
        delivery_outlets_id: "",
        order_remarks: "",
        customer_service_remarks: "",
        private_remarks: "",

        // 拆单动作，提交内容
        express_consignee_name: "",
        express_consignee_phone: "",
        express_consignee_address: "",
        express_consignee_lng: "",
        express_consignee_lat: "",
        express_consignee_adcode: "",
      },

      isInDrawer: false,
      isShowCancel: false,
      
      isInBlackList: false,
      blackListRemarks: "",

      openTime: "",
      isOtherPlatOrder: false,
      isAllEdit: false,

      isCustomDlvr: false,
      isCustomFreight: false,
      newFreight: 0,
      newAmount: 0,

      showPay: false,
      showRePay: false,
      update_audit: '',

      isShowSmsLog: false,
      isShowSendSms: false,
      smsPhoneList: [],
    }
  },
  computed: {
    // 判断收货 取货时间是否有改动
    isTimeUpdate() {
      // 如果是自提 则判断取货时间
      if (this.isUpdateUserInfo) {
        if (this.outletOrder.suborder_type === 1) {
          if (this.outletOrder.pick_up_info.pick_up_goods_time_start != this.updateUserInfo.pick_up_delivery_order.pick_up_info.pick_up_goods_time_start || this.outletOrder.pick_up_info.pick_up_goods_time_end != this.updateUserInfo.pick_up_delivery_order.pick_up_info.pick_up_goods_time_end) {
            return true
          } else {
            return false
          }
        } else{
          if (this.outletOrder.delivery_info.delivery_time_start != this.updateUserInfo.pick_up_delivery_order.delivery_info.delivery_time_start || this.outletOrder.delivery_info.delivery_time_end != this.updateUserInfo.pick_up_delivery_order.delivery_info.delivery_time_end) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }

    },
    oldDeliveryInfo() {
      return this.updateUserInfo.pick_up_delivery_order.delivery_info || {}
    },
    oldPickUpInfo() {
      return this.updateUserInfo.pick_up_delivery_order.pick_up_info || {}
    },
    oldCakeAccessoryList() {
      return this.updateUserInfo.pick_up_delivery_order.cake_accessory_list || []
    }
  },
  created() {
    if(this.orderNo){ // 如果是抽屉页
      this.isInDrawer = true
      this.order_no = this.orderNo
    }else if(this.$route.query.order_no){ // 如果是新开页
      this.order_no = this.$route.query.order_no
    }

    this.formState.order_no = this.order_no
  },
  mounted() {
    this.initData()
  },
  methods: {
    formatDetailOrderStatus,
    async initData() {
      const { data, code, timestamp } = await getCakeOrderDetail({
        order_no: this.order_no,
      })
      if (code != 0) return
      
      this.openTime = timestamp
      this.isOtherPlatOrder = data.order_source >= 20

      this.data = { ...data }
      this.formState.pay_subtype = data.pay_subtype
      this.formState.order_remarks = data.order_remarks
      this.formState.customer_service_remarks = data.customer_service_remarks
      this.formState.private_remarks = data.private_remarks

      this.data.order_status_show = formatDetailOrderStatus(data.order_status)

      this.payData = [{
        key: "0",
        wx_pay_amount:                data.wx_pay_amount,
        vip_card_pay_amount:          data.vip_card_pay_amount,
        stored_value_card_pay_amount: data.stored_value_card_pay_amount,
        alipay_amount:                data.alipay_amount,
        other_pay_amount:             data.other_pay_amount,
        cash_delivery_amount:         data.cash_delivery_amount,
        on_account_amount:            data.on_account_amount,
      }]

      // 网点订单解析
      let time_start = ""
      let time_end   = ""
      if (data.pick_up_delivery_order && data.pick_up_delivery_order.suborder_no) {
        let outletOrder = data.pick_up_delivery_order
            outletOrder.suborder_statusname = formatPdSubOrderStatus(outletOrder.suborder_status)
        if (outletOrder.suborder_type == 1) { // 如果是自提单
          this.isTimePoint = (outletOrder.pick_up_info.pick_up_goods_time_start == outletOrder.pick_up_info.pick_up_goods_time_end)
          // 如果时间为空 服务端都返回 1970-01-01 08:00:00
          time_start = outletOrder.pick_up_info.pick_up_goods_time_start
          time_end   = outletOrder.pick_up_info.pick_up_goods_time_end
          this.formState.pick_up_outlets_id = outletOrder.outlets_id
        } else { // 如果是配送单
          this.isTimePoint = (outletOrder.delivery_info.delivery_time_start == outletOrder.delivery_info.delivery_time_end)
          // 如果时间为空 服务端都返回 1970-01-01 08:00:00
          time_start = outletOrder.delivery_info.delivery_time_start
          time_end   = outletOrder.delivery_info.delivery_time_end
          this.formState.delivery_outlets_id = outletOrder.outlets_id
          outletOrder.delivery_info.consignee_lng = outletOrder.delivery_info.consignee_lng_lat.split(" ")[0]
          outletOrder.delivery_info.consignee_lat = outletOrder.delivery_info.consignee_lng_lat.split(" ")[1]
        }
        let date = time_start.split(" ")[0]
        let time = time_start.split(" ")[1].substr(0, 5) + "-" + time_end.split(" ")[1].substr(0, 5)
        // outletOrder.goods_time = [date, time]
        this.goods_time = [date, time]
        this.allOutletGoodsList = outletOrder.goods_list
        this.leftOutletGoodsList = outletOrder.goods_list
        this.cakeList = outletOrder.cake_accessory_list

        this.outletOrder = outletOrder
        this.isAllEdit = [2,3].includes(data.order_status)
        
        this.isCustomDlvr = data.order_source == 10 && outletOrder.suborder_type == 2
        this.newFreight = data.freight_amount
      }

      this.$emit('getDetail', this.data)

      // 赠品赠券
      this.giftList = data.event_gift_goods_list || []
      this.conponList = data.event_gift_coupon_list.map((item, i) => {
        item.id = i
        return item
      }) || []

      // 订单修改状态 （1-未修改，2-已修改） 如果是2，则赋值修改对比数据
      if (data.order_update_status === 2) {
        this.isUpdateUserInfo = true
        this.updateUserInfo = data.order_update_historical_data
      }

      // 发票
      getOrderInvoice({ order_no: this.order_no }).then(res=>{
        if(res.data){
          this.invoiceStatus = res.data.audit_status || -1
        }
      })
      // 黑名单
      if(this.data.member_id){
        getUserBlackInfo({ member_id: this.data.member_id }).then(res=>{
          if(res.code == 0){
            this.isInBlackList = res.data.blacklist_status==2
            this.blackListRemarks = res.data.blacklist_remarks || ""
          }
        })
      }

      // 售后申请的表单
      const suborder_list = []
      if(this.outletOrder){
        suborder_list.push(this.outletOrder)
      }
      // if(this.isHaveExpress){
      //   suborder_list.push(this.expressOrder)
      // }
      this.refundApplyForm = {
        order_no: data.order_no,
        suborder_list: suborder_list,
        order_amount: data.order_amount,
        // goods_amount: data.goods_amount,
        freight_amount: data.freight_amount,
        order_source: data.order_source,
      }
    },

    async savePrivateRemarks(){
      updatePrivateRemarks({
        open_time: this.openTime,
        order_no: this.data.order_no,
        private_remarks: this.formState.private_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
          this.initData()
        }
      })
    },
    async saveCustomerRemarks(){
      updateCustomerRemarks({
        open_time: this.openTime,
        order_no: this.data.order_no,
        customer_service_remarks: this.formState.customer_service_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
          this.initData()
        }
      })
    },

    switchRefund(bool){
      const refund_switch = bool?1:2
      updateRefundSwitch({
        order_no: this.data.order_no,
        refund_switch: refund_switch
      }).then(res => {
        if (res.code === 0) {
          this.data.refund_switch = refund_switch
          this.$message.success("已修改")
        }
      })
    },

    openUserDetail(){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: this.data.member_id },
      });
      window.open(newPage.href, "_blank");
    },

    showPaySub(){
      this.$refs.payTypeSelect.$el.click()
    },
    getCODPayType(subpaytype){
      subpaytype = subpaytype || 0
      let item = this.subCODPayTypeList.find(el=>{
        if(subpaytype == el.id) return el
      })
      return item && item.name || "未知"
    },
    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },
    // 修改网点
    handlerSelectOutlets() {
      this.isSelectOutlets = true
    },
    // 确认选择网点
    handlerSureOutlets(row) {
      if(this.outletOrder.suborder_type==1){
        this.formState.pick_up_outlets_id = row.outlets_id
      }else{
        this.formState.delivery_outlets_id = row.outlets_id
        // 如果是客服下单  可以自定义运费
        if(this.isCustomDlvr){
          this.newFreight = row.freight_amount
          this.isCustomFreight = !row.delivery_region_id
        }
      }
      this.outletOrder.outlets_name = row.outlets_name
    },

    // 如果切换的负责网点没有库存，则对勾选的商品进行拆单赋值
    handlerOutletNoGoodsSure(infos) {
      if(this.outletOrder.suborder_type==1){
        this.formState.pick_up_outlets_id = infos.outlets.outlets_id
      }else{
        this.formState.delivery_outlets_id = infos.outlets.outlets_id
      }
      this.outletOrder.outlets_name = infos.outlets.outlets_name
    },

    // 保存
    handlerSave(update_audit) {
      this.update_audit = update_audit

      if(this.isCustomDlvr){
        if(Number(this.newFreight) != Number(this.data.freight_amount)){
          this.newAmount = Number(this.data.goods_amount) + Number(this.newFreight)
          this.showRePay = true
          return
        }
      }

      this.submitSave()
    },

    async submitSave(payParams){
      let params = JSON.parse(JSON.stringify(this.formState))
          params.freight_amount = this.data.freight_amount
      if(payParams){
        Object.assign(params, payParams, {
          freight_amount: this.newFreight
        })
      }

      if(!payParams){
        if(this.data.pay_type != 10){ // 不是货到付款
          params.pay_subtype = ""
        }
      }
      
      // 1 门店子订单
      if (this.outletOrder.suborder_type === 2) { // 配送单
        params.delivery_time_start = this.goods_time[0] + " " + this.goods_time[1].split("-")[0]
        params.delivery_time_end   = this.goods_time[0] + " " + this.goods_time[1].split("-")[1]
        params.delivery_consignee_name    = this.outletOrder.delivery_info.consignee_name
        params.delivery_consignee_phone   = this.outletOrder.delivery_info.consignee_phone
        // params.delivery_consignee_address = this.outletOrder.delivery_info.consignee_address
        params.delivery_consignee_address = this.outletOrder.delivery_info.consignee_address + (this.outletOrder.delivery_info.consignee_address_detail||"")

        // 经纬度
        params.delivery_consignee_adcode  = this.outletOrder.delivery_info.consignee_adcode
        params.delivery_consignee_lng     = this.outletOrder.delivery_info.consignee_lng
        params.delivery_consignee_lat     = this.outletOrder.delivery_info.consignee_lat

        if (!this.outletOrder.delivery_info.consignee_adcode) {
          const res = await getAdcode(
            params.delivery_consignee_lat,
            params.delivery_consignee_lng
          )
          if (res.status === 0 && res.result.address_reference.town)
            params.delivery_consignee_adcode = res.result.address_reference.town.id
        }

        if(!params.delivery_consignee_address){
          this.$message.warning('收货地址不能为空')
          return
        }
        if(!params.delivery_consignee_phone){
          this.$message.warning('手机号不能为空')
          return
        }
      } else { // 自提单
        params.pick_up_goods_time_start = this.goods_time[0] + " " + this.goods_time[1].split("-")[0]
        params.pick_up_goods_time_end   = this.goods_time[0] + " " + this.goods_time[1].split("-")[1]
        params.pick_up_recipient_name  = this.outletOrder.pick_up_info.recipient_name
        params.pick_up_recipient_phone = this.outletOrder.pick_up_info.recipient_phone
      }
      params.cake_accessory_data = JSON.stringify(this.outletOrder.cake_accessory_list)

      params.update_audit = this.update_audit
      params.open_time = this.openTime
      changeAuditCakeOrder(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功")
          this.$emit("ok", res)
          if(!this.isInDrawer){
            this.initData()
          }
        }
      })
    },

    // 地址输入
    async handleSearch(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    async handleChange(value) {
      const item = this.addressList.find((item) => item.id === value)
      this.outletOrder.delivery_info.consignee_address = (item.address || '') + (item.title || '')
      this.outletOrder.delivery_info.consignee_lat = item.location.lat
      this.outletOrder.delivery_info.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        this.formState.consignee_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
      } else {
        this.formState.consignee_adcode = ''
        this.$message.warning("查询错误，请重试");
      }

      // 自动切换网点
      if(this.formState.consignee_adcode){
        let area = await searchShopDeliveryArea({
          lat: this.outletOrder.delivery_info.consignee_lat,
          lng: this.outletOrder.delivery_info.consignee_lng
        }, this.formState.consignee_adcode)

        if (area) {
          if(this.outletOrder.outlets_name != area.outlets_name){
            this.$message.info("网点自动切换为："+area.outlets_name)
          }
          this.formState.delivery_outlets_id = area.outlets_id
          this.outletOrder.outlets_name = area.outlets_name
          if(this.isCustomDlvr){
            this.newFreight = area.freight
            this.isCustomFreight = !area.delivery_region_id
          }
        } else {
          this.$message.warning("超区，不在配送范围内");
          this.formState.delivery_outlets_id = ""
          this.outletOrder.outlets_name = ""
          if(this.isCustomDlvr){
            this.newFreight = 0
            this.isCustomFreight = false
          }
        }
      }
    },


    onCustomFreightChange(){
      // test
    },

    // 传入key,value和序列，去修改数据作对比
    hasCakeUpdate(key, value, index) {
      if (this.oldCakeAccessoryList.length > 0) {
        return value != this.oldCakeAccessoryList[index][key]
      } else {
        return false
      }
    },

    hasDataUpdate(key, value) {
      if (this.isUpdateUserInfo) {
        if (key.includes('.')) {
          if (this.outletOrder.suborder_type === 1) {
            return (this.oldPickUpInfo[key.split('.')[1]] != value)
          } else {
            return (this.oldDeliveryInfo[key.split('.')[1]] != value)
          }
        }
        return (this.updateUserInfo[key] !== value)
      }
      return false
    },

    // 取消订单
    handlerCancel(){
      if(this.checkNotOtherPlatOrder()){
        this.isShowCancel = true
      }
    },
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        order_no: this.data.order_no
      }
      const _this = this
      cancelCakeOrder(params).then(res => {
        if (res.code === 0) {
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.$emit('order-canceled')
          if(!_this.isInDrawer){
            _this.initData()
          }
        }
      })
    },

    getPayStatus(){
      this.initData()
      this.showPay = false
    },

    onRePayOk(params){
      this.showRePay = false
      this.submitSave(params)
    },

    getCakeSkuInfo(order_goods_id){
      let sku = this.outletOrder.goods_list.find(el=>{
        return el.order_goods_id == order_goods_id
      })
      return sku || {}
    },

    checkNotOtherPlatOrder(){
      if(this.isOtherPlatOrder){
        this.$message.info("第三方订单到母订单操作")
        return false
      }
      return true
    },
    openOtherPlatOrder(){
      const newPage = this.$router.resolve({
        name: "otherPlatOrderDetail",
        query: { other_order_id: this.data.other_order_id },
      });
      window.open(newPage.href, "_blank");
    },

    showSendSms(){
      let phoneList = []
      if(this.outletOrder){
        if(this.outletOrder.suborder_type == 1){
          phoneList.push({
            type: "取货人",
            name: this.outletOrder.pick_up_info.recipient_name,
            phone: this.outletOrder.pick_up_info.recipient_phone,
          })
        }
        if(this.outletOrder.suborder_type == 2){
          phoneList.push({
            type: "收货人",
            name: this.outletOrder.delivery_info.consignee_name,
            phone: this.outletOrder.delivery_info.consignee_phone,
          })
          if(this.outletOrder.delivery_info.contacts_phone){
            phoneList.push({
              type: "联系人",
              name: this.outletOrder.delivery_info.contacts_name,
              phone: this.outletOrder.delivery_info.contacts_phone,
            })
          }
        }
      }
      if(this.data.place_order_phone){
        if(this.data.order_source == 10 || this.data.order_source == 15){
          phoneList.push({
            type: "下单人",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }else{
          phoneList.push({
            type: "下单用户",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }
      }
      this.smsPhoneList = phoneList
      this.isShowSendSms = true
    },
  },
}
</script>

<style lang="less">
.has-update {
  background-color: #fff;
  border-color: #f5222d;
  .ant-select-selection {
    border-color: #f5222d;
  }
  .ant-cascader-input {
    border-color: #f5222d;
  }
  input{
    border-color: #f5222d;
  }
}
</style>
<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.operate-fixed-footer{
  border-top: 1px solid #eee;
  z-index: 9;
}
.col-item{
  min-height: 32px;
  min-width: 280px;
  display: flex;
  align-items: center;
}
.addr-region{
  margin-left: 10px;
  color: #ccc;
}
.thingtohide {
  animation: cssAnimation 0s ease-in 2s forwards;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out ;
}

.btn-link{
  cursor: pointer;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
  &.r{
    margin-left: 20px;
    margin-right: 0;
  }
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
</style>
