<template>
  <div>
    <a-modal
      :visible.sync="show"
      title="选择网点"
      destroyOnClose
      width="850px"
      @cancel="$emit('update:show', false)"
      :footer="null"
    >
      <div class="modal-search-bar">
        <a-form-model
          layout="horizontal"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          ref="searchRef"
          :model="searchForm"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="outlets_name" label="网点名称">
                <a-input allowClear v-model="searchForm.outlets_name" placeholder="网点名称" @keydown.enter="handlerSearch"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item style="margin-left:20px;">
                <a-button @click="handlerSearch" type="primary">搜索</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <base-table
        ref="goodsTable"
        rowKey="outlets_id"
        :rowClassName="getRowClass"
        :columnsData="columns"
        :tableData="tableData"
        :total="total"
        :customHeight="400"
        :getListFunc="initData"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count"
      >
        <template #operation="{ record }">
          <a-button type="link" @click="handlerSelect(record)">选择</a-button>
        </template>
      </base-table>
    </a-modal>
    <!-- <outlets-nogoods-modal
      :show.sync="isOuteletsNoGoods"
      :goodsList="noStockgoodsList"
      :type="type"
      @ok="handlerOutletNoGoodsSure"
    /> -->
  </div>
</template>

<script>
import { 
  getPickupShopsList,
  // getDeliveryOutletsList, 
  // getShopNotStockGoodsList,
  // getOutletsNotStockGoodsList
} from "@/api/customer"
import { 
  getDeliveryOutletsList, 
  // getPickUpOutletsList 
} from "@/api/order/create"
import { 
  formatOutletsType
} from "@/utils/type"
import { 
  getDeliveryArea, 
  sortOutletsListByRegion 
} from "@/utils/delivery.js"

// import OutletsNogoodsModal from './outlets-nogoods-modal.vue'

export default {
  components: {
    // OutletsNogoodsModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, Number],
    },
    orderId: {
      type: [String, Number],
    },
    outletOrder: {
      type: Object
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      // isOuteletsNoGoods: false,
      // noStockgoodsList: [],
      activeOutlets: null,
      searchForm: {
        outlets_name: "",
        page: 1,
        page_count: 10,
      },
      columns: [],
      baseColumns: [
        {
          title: "类型",
          dataIndex: "outlets_type",
          align: "center",
          width: 80,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatOutletsType(val),
        },
        {
          title: "网点名称",
          dataIndex: "outlets_name",
          align: "center",
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "center",
          width: 130,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val.replace(",", "\n") },
        },
        {
          title: "覆盖范围",
          dataIndex: "cover_name",
          align: "center",
          width: 80,
        },
        {
          title: "剩余运力",
          dataIndex: "transport_availability",
          align: "center",
          width: 80,
        },

        {
          title: "有库存",
          dataIndex: "have_stock",
          align: "center",
          width: 80,
        },
        {
          title: "运费",
          dataIndex: "freight",
          align: "center",
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 80,
          slots: {
            customRender: "operation",
          },
        },
      ],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let outletsList
      let res
      if(this.type == 1){
        res = await getPickupShopsList({ order_no: this.orderId })
        this.columns = this.baseColumns.filter(item =>!["cover_name","transport_availability","freight"].includes(item.dataIndex))

        outletsList = res.data.list.map(item => {
          item.have_stock = item.have_stock === 2 ? '是' : '否'
          return item
        })
      }else if(this.type == 2){
        // test 不需要adcode了 因为要查全国的配送站
        // res = await getDeliveryOutletsList({ adcode: this.outletOrder.delivery_info.consignee_adcode })
        res = await getDeliveryOutletsList()
        this.columns = this.baseColumns

        outletsList = sortOutletsListByRegion(res.data.list, {
          lat: this.outletOrder.delivery_info.consignee_lat,
          lng: this.outletOrder.delivery_info.consignee_lng
        }).map(item => {
          item.cover_name = item.is_cover === 2 ? '是' : '否'
          item.have_stock = item.have_stock === 2 ? '是' : '否'
          return item
        })
      }
      this.baseList = outletsList
      this.tableData = outletsList
      this.total = res.data.total_count
    },
    // 保存
    handlerSearch() {
      this.tableData = this.baseList.filter(item => item.outlets_name.includes(this.searchForm.outlets_name))
    },
    // async handlerSelect(row) {
    //   this.activeOutlets = row
    //   let res
    //   if(this.type==1){
    //     // 门店有库存么？没库存提示
    //     res = await getShopNotStockGoodsList({ order_no: this.orderId, outlets_id: row.outlets_id })
    //   }else if(this.type==2){
    //     // 配送站有库存么？没库存提示（引导拆单）
    //     res = await getOutletsNotStockGoodsList({ order_no: this.orderId, outlets_id: row.outlets_id })
    //   }

    //   if (res && res.code == 0) {
    //     if(res.data.list.length){
    //       // test 过滤蛋糕有个问题：列表就不显示了
    //       // this.noStockgoodsList = res.data.list.filter(el=>el.goods_type!=3)
    //       this.noStockgoodsList = res.data.list
    //       this.isOuteletsNoGoods = true
    //     }else {
    //       this.$emit("ok", row)
    //       this.$emit("update:show", false)
    //     }
    //   }
    // },
    async handlerSelect(row) {
      this.activeOutlets = row
      if(this.type == 1){
        this.$emit("ok", row)
        this.$emit("update:show", false)
      }else{
        const area = getDeliveryArea([row], {
          lat: this.outletOrder.delivery_info.consignee_lat,
          lng: this.outletOrder.delivery_info.consignee_lng
        })
        if (area) {
          this.$emit("ok", Object.assign({}, row, {
            delivery_region_id: area.delivery_region_id,
            freight_amount: area.freight,
          }))
          this.$emit("update:show", false)
        } else {
          this.$confirm({
            title: "温馨提示",
            content: '收货地址不在所选网点的配送范围，确认选择？',
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.$emit("ok", Object.assign({}, row, {
                delivery_region_id: '',
                freight_amount: 0,
              }))
              this.$emit("update:show", false)
            }
          })
        }
      }
    },

    // 如果切换的负责网点没有库存，则对勾选的商品进行拆单赋值
    handlerOutletNoGoodsSure(isSplit, rows) {
      // 如果确认拆单
      this.$emit("getSplitGoods", {
        outlets: this.activeOutlets,
        goods: rows,
        isSplit: isSplit
      })
      this.$emit("update:show", false)
    },

    getRowClass(record) {
      if(this.type == 1) return 'cover-status'
      if(this.type == 2){
        if (record.is_cover === 2) {
          return 'cover-status'
        } else {
          return 'cover-disable-status'
        }
      } 
    },
  },
}
</script>

<style lang="less">
.ant-modal-body {
  position: relative;
  margin-bottom: 10px;
}
.cover-disable-status {
  color: #ccc;
}
</style>